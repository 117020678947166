import { IChallengeInState, IChallengeOrder } from './Challenges';

interface OrderItemsArgs<Item, OrderedItem> {
  items: Item[];
  order: OrderedItem[];
  predicate(item: Item, orderedItem: OrderedItem): boolean;
}

export const getOrderedItems = <Item, OrderedItem = Item>({
  items,
  order,
  predicate,
}: OrderItemsArgs<Item, OrderedItem>): any[] => {
  if (!order?.length) {
    return items;
  }

  const orderedItems = order.map((orderedItem) => {
    const item = items.find((item) => predicate(item, orderedItem));

    if (!item) {
      return;
    }

    return item;
  });

  const tail = items.filter((item) => {
    const isInOrder = order.find((orderedItem) => predicate(item, orderedItem));

    return !isInOrder;
  });

  return [...orderedItems.filter(Boolean), ...tail];
};

export function getOrderedChallenges(
  items: IChallengeInState[],
  order: IChallengeOrder[],
): IChallengeInState[] {
  return getOrderedItems<IChallengeInState, IChallengeOrder>({
    items,
    order,
    predicate: (item, itemSorted) => itemSorted?.id === item?.id.substr(0, 5),
  });
}
