import { getSectionTitle } from './getSectionTitle';
import { useTranslation } from '@wix/yoshi-flow-editor';
import challengeSettings from '../../../../../../settingsParams';
import { SectionAbbreviation, sectionIds, VisitorSections } from './sectionIds';
import { SettingsKeysAbbreviation } from '../../../../../constants/settingsKeysAbbreviation';
import { getOrderedItems } from '../../../../../../../ChallengesList/Settings/tabs/Challenges/utils';
import { useSettingsWithAbbreviation } from './useSettingsWithAbbreviation';

function useIsSectionInSidebar() {
  const settings = useSettingsWithAbbreviation();

  return (sectionId: VisitorSections): boolean => {
    switch (sectionId) {
      default:
      case 'about':
      case 'overview':
        return false;
      case 'instructor':
        return settings.get(
          SettingsKeysAbbreviation.visitorPageInstructorShowInSidebar,
        );
      case 'start_date':
        return settings.get(
          SettingsKeysAbbreviation.visitorPageStartDayShowInSidebar,
        );
      case 'price':
        return settings.get(
          SettingsKeysAbbreviation.visitorPagePriceShowInSidebar,
        );
      case 'share':
        return settings.get(
          SettingsKeysAbbreviation.visitorPageShareShowInSidebar,
        );
      case 'group_discussion':
        return settings.get(
          SettingsKeysAbbreviation.visitorPageGroupDiscussionShowInSidebar,
        );
    }
  };
}

function convertSectionIdsToItems(sectionIds, t) {
  return sectionIds.map((item) => {
    return {
      id: item,
      label: getSectionTitle(item, t),
    };
  });
}

const getOrderedSections = (
  items: VisitorSections[],
  order: SectionAbbreviation[],
  hideStartDateEnabled: boolean,
) => {
  return getOrderedItems<VisitorSections, SectionAbbreviation>({
    order,
    items: items.filter((item) =>
      hideStartDateEnabled ? item !== 'start_date' : true,
    ),
    predicate(
      item: VisitorSections,
      orderedItem: SectionAbbreviation,
    ): boolean {
      const [orderSection] = Object.entries(SectionAbbreviation).find(
        ([_section, abbreviation]) => abbreviation === orderedItem,
      );
      return orderSection === item;
    },
  });
};

export const useSidebarItems = (hideStartDateEnabled?: boolean) => {
  const { t } = useTranslation();
  const settings = useSettingsWithAbbreviation();
  const isSectionInSidebar = useIsSectionInSidebar();
  const isSidebarEnabled = settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const { order } = settings.get(
    SettingsKeysAbbreviation.visitorPageSidebarSectionsOrder,
  );

  const sections = isSidebarEnabled
    ? sectionIds.filter((id) => isSectionInSidebar(id))
    : [];

  return convertSectionIdsToItems(
    getOrderedSections(
      sections as VisitorSections[],
      order,
      hideStartDateEnabled,
    ),
    t,
  );
};

export const useBodyItems = (hideStartDateEnabled?: boolean) => {
  const { t } = useTranslation();
  const settings = useSettingsWithAbbreviation();
  const isSectionInSidebar = useIsSectionInSidebar();
  const isSidebarEnabled = settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const { order } = settings.get(
    SettingsKeysAbbreviation.visitorPageBodySectionsOrder,
  );
  const sections = isSidebarEnabled
    ? sectionIds.filter((id) => !isSectionInSidebar(id))
    : sectionIds;

  return convertSectionIdsToItems(
    getOrderedSections(
      sections as VisitorSections[],
      order,
      hideStartDateEnabled,
    ),
    t,
  );
};
