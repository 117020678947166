import * as React from 'react';

import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
} from 'wix-ui-tpa/cssVars';
import { useSettingsEvent } from '../../../../../../contexts/SettingsEvents/SettingsEvents';
import { IStyledButtonProps } from '../interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes, st } from './SidebarLayoutButton.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export const SidebarLayoutButton: FCWithChildren<IStyledButtonProps> = (
  props,
) => {
  const { buttonState: SettingsEventsButtonState } = useSettingsEvent();
  const { isMobile } = useEnvironment();
  const {
    buttonState = SettingsEventsButtonState,
    className,
    size = ButtonSIZE.small,
    fullWidth = isMobile,
    priority = ButtonPRIORITY.primary,
  } = props;

  return (
    <Button
      fullWidth={fullWidth}
      data-hook={props.dataHook || null}
      className={st(classes.root, { buttonState }, className)}
      disabled={props.disabled}
      priority={priority}
      size={size}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
