import React from 'react';

import { SelectedPaymentOption } from '../../../../components/Pricing/interfaces';
import { IChallengeJoinRestriction } from '../../../../interfaces';

export interface IVisitorPageBaseContext {
  selectedPaymentOption: SelectedPaymentOption;
  isEmptyStartDateError: boolean;
  startDate: string;
  challengeJoinRestrictions: IChallengeJoinRestriction[];
  isCantJoinModalOpened: boolean;
  isDisableEligiblePlans: boolean;
  getStartMinDate: Function;
  onPaymentSelected: Function;
  onStartDateChange: Function;
  onJoinValidationError: Function;
  onCantJoinSecondaryClick: Function;
  onCantJoinClose: Function;
}

export const VisitorPageBaseContext =
  React.createContext<IVisitorPageBaseContext>(null);

export function useVisitorPageBase(): IVisitorPageBaseContext {
  return React.useContext(VisitorPageBaseContext);
}
